import styled, {css} from 'styled-components';
import {Color} from './constants';

export const Card = styled.button`
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  border: 1px solid ${Color.black_10};
  box-shadow: 0 0 10px ${Color.black_10};
  border-radius: 10px;
  padding: 10px;
  cursor: ${({clickable}) => (clickable ? 'pointer' : 'unset')};
  & :active {
    opacity: ${({clickable}) => (clickable ? 0.3 : 1)};
  }
`;

export const Text10 = styled.div`
  font-size: 10px;
  line-height: 1.57;
`;

export const Text12 = styled.div`
  font-size: 12px;
  line-height: 1.57;
`;

export const Text14 = styled.div`
  font-size: 14px;
  line-height: 1.57;
`;

export const Text16 = styled.div`
  font-size: 16px;
  line-height: 1.57;
`;

export const Text18 = styled.div`
  font-size: 18px;
  line-height: 1.57;
`;

export const Text20 = styled.div`
  font-size: 20px;
  line-height: 1.57;
`;

export const Text22 = styled.div`
  font-size: 22px;
  line-height: 1.57;
`;

export const Text24 = styled.div`
  font-size: 24px;
  line-height: 1.57;
`;

export const FlexRowCenterStart = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexRowCenterEnd = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const FlexRowCenterCenter = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FlexRowEndBetween = css`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const FlexRowCenterBetween = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FlexRowStartStart = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FlexColumnCenterBetween = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const FlexColumnCenterAround = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const FlexColumnCenterStart = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const FlexColumnCenterCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FlexColumnStartStart = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FlexColumnStretchStart = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

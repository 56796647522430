import * as L from './Lang';
import {ORDER_STATUS} from '../constants';

export const isNotAllowViewDetail = (status) => {
  return !(
    status === ORDER_STATUS.TICKET_CHECKIN ||
    status === ORDER_STATUS.UNPAID_ORDER ||
    status === ORDER_STATUS.PAYMENT_REFUNDED
  );
};

export function aggregateConcessions(concessions) {
  return (
    concessions?.reduce((acc, cur) => {
      const targetIdx = acc.findIndex((i) => i.id === cur.ItemId);
      if (targetIdx > -1) {
        acc[targetIdx].quantity += cur.Quantity;
      } else {
        acc = [
          ...acc,
          {
            id: cur.ItemId,
            quantity: cur.Quantity,
            finalPriceCents: cur.FinalPriceCents,
          },
        ];
      }
      return acc;
    }, []) || []
  );
}

export function aggregateTickets(tickets) {
  return (
    tickets?.reduce((acc, cur) => {
      const targetIdx = acc.findIndex(
        (i) => i.ticketTypeCode === cur.TicketTypeCode,
      );
      if (targetIdx > -1) {
        acc[targetIdx].quantity++;
      } else {
        acc = [
          ...acc,
          {
            id: cur.Id,
            quantity: 1,
            priceCents: cur.PriceCents,
            ticketTypeCode: cur.TicketTypeCode,
            altDescription: cur.AltDescription,
            description: cur.Description,
          },
        ];
      }
      return acc;
    }, []) || []
  );
}

export const formatSessions = (sessions, film) =>
  sessions?.map((s) => ({
    ...s,
    showDateTime: s.ShowingRealDateTime,
    aggregateTickets: aggregateTickets(s.Tickets),
    tickets: s.Tickets.map((t) => ({
      ...t,
      seatRowId: t.SeatRowId,
      seatNumber: t.SeatNumber,
      description: L.d({zh: t.AltDescription, en: t.Description}),
      barcode: t.Barcode,
    })),
    film: film,
  })) || null;

export function parseBookingPrintStream(bookingPrintStream) {
  if (!bookingPrintStream) {
    return null;
  }
  return (
    bookingPrintStream.match(/([0-9]*:[A-Za-z0-9]*)/gim)?.map((tn) => ({
      sequenceNum: Number.parseInt(tn.split(':')[0], 10),
      ticketNum: tn.split(':')[1],
    })) || null
  );
}

import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import Close from '../images/close-icon.png';

const useModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    if (modalRef) {
      modalRef.current = {
        open: () => {
          setVisible(true);
        },
        close: () => {
          setVisible(false);
        },
        set: ({elem, type}) => {
          setContent(elem);
        },
      };
    }
  }, [modalRef, setVisible]);

  const modalElem = (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={500}
      visible={visible}
      closeIcon={<img src={Close} alt="close" width={20} height={20} />}
      onCancel={() => {
        setVisible(false);
      }}>
      <Wrapper>{content}</Wrapper>
    </Modal>
  );
  return {
    modalElem: modalElem,
    modalRef: modalRef,
  };
};

const Wrapper = styled.div`
  padding: 20px;
  border-radius: 10px;
`;

export default useModal;

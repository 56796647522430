export const Color = {
  white: 'rgba(255,255,255,1)',
  black: 'rgba(0,0,0,1)',
  black_10: 'rgba(0,0,0,0.1)',
  black_20: 'rgba(0,0,0,0.2)',
  black_30: 'rgba(0,0,0,0.3)',
  black_50: 'rgba(0,0,0,0.5)',
  black_70: 'rgba(0,0,0,0.7)',
  gray: 'rgba(200,200,200,1)',
  orange: 'rgba(238,109,34,1)',
  yellow: 'rgba(254,197,53,1)',
  red: 'rgba(255,113,113,1)',
  green: 'rgba(119,146,105,1)',
};

export const Breakpoints = {
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const SITE_LINKS_CONFIG = [
  {
    title: '首頁',
    route: '/',
  },
  {
    title: '影城介紹',
    route: '/intro',
  },
  {
    title: '業務專區',
    route: '/business',
  },
  {
    title: '信用卡優惠',
    route: '/credit-card-info',
  },
  {
    title: '電影介紹',
    route: '/film-list',
  },
  {
    title: '最新公告',
    route: '/news-list',
  },
  {
    title: '我的票夾',
    route: '/wallet',
  },
  {
    title: '餐影服務',
    route: '/meal-list',
  },
];

export const FILM_RATING = {
  G: 'G',
  P: 'P',
  PG: 'PG',
  PG12: 'PG12',
  PG15: 'PG15',
  R: 'R',
};

export const FILM_RATING_DISPLAY = {
  G: {
    title: {
      zh: '普遍級',
      en: 'G',
    },
    color: 'rgb(77, 169, 36)',
  },
  P: {
    title: {
      zh: '保護級',
      en: 'P',
    },
    color: 'rgb(19,141,226)',
  },
  PG: {
    title: {
      zh: '輔導級',
      en: 'PG',
    },
    color: 'rgb(247, 154, 11)',
  },
  PG12: {
    title: {
      zh: '輔12級',
      en: 'PG12',
    },
    color: 'rgb(220, 151, 7)',
  },
  PG15: {
    title: {
      zh: '輔15級',
      en: 'PG15',
    },
    color: 'rgb(231,97,9)',
  },
  R: {
    title: {
      zh: '限制級',
      en: 'R',
    },
    color: 'rgb(220,0,16)',
  },
};

export const BOOK_STEPS = {
  choose_ticket_type: 'choose_ticket_type',
  ticket_amount: 'ticket_amount',
  choose_meal: 'choose_meal',
  choose_seat: 'choose_seat',
  ticket_detail: 'ticket_detail',
};

export const BOOK_STEPS_DISPLAY = [
  {
    key: BOOK_STEPS.choose_ticket_type,
    title: '票種',
    header: '選取票種',
  },
  {
    key: BOOK_STEPS.ticket_amount,
    title: '張數',
    header: '選取數量',
  },
  {
    key: BOOK_STEPS.choose_meal,
    title: '餐點',
    header: '選取餐點',
  },
  {
    key: BOOK_STEPS.choose_seat,
    title: '座位',
    header: '選取座位',
  },
  {
    key: BOOK_STEPS.ticket_detail,
    title: '明細',
    header: '交易明細',
  },
];

export const SEAT_STATUS_TYPE = {
  available: 'available',
  occupied: 'occupied',
  preserve: 'preserve',
  wheelchair: 'wheelchair',
  broken: 'broken',
  special: 'special',
  own: 'own',
};

export const SEAT_STATUS_CODE_TYPE = {
  0: SEAT_STATUS_TYPE.available,
  1: SEAT_STATUS_TYPE.occupied,
  2: SEAT_STATUS_TYPE.occupied,
  3: SEAT_STATUS_TYPE.occupied,
  5: SEAT_STATUS_TYPE.occupied,
  7: SEAT_STATUS_TYPE.occupied,
  999: SEAT_STATUS_TYPE.own,
};

export const SEAT_STATUS_TYPE_DISPLAY = [
  {
    key: SEAT_STATUS_TYPE.available,
    title: '可選座位',
    color: Color.black_30,
  },
  {
    key: SEAT_STATUS_TYPE.occupied,
    title: '已訂座位',
    color: Color.red,
  },
  {
    key: SEAT_STATUS_TYPE.own,
    title: '您的座位',
    color: Color.green,
  },
];

// 一般票種
// 全票
// 優待票
// 早場優待票
// 愛心 /敬老票

// 銀行優惠 (優惠待補)
// 台新銀行
// 星展銀行
// 玉山銀行
// 聯邦銀行

// 團券兌換/免費票兌換 (尚缺票券兌換頁面)
// 團體電影優惠券
// 免費電影兌換券

export const INPUT_TYPES = {
  date: 'date',
  text: 'text',
  password: 'password',
  select: 'select',
  multiSelect: 'multiSelect',
};

export const WALLET_TYPE = {
  film: 'film',
  meal: 'meal',
};

export const WALLET_TYPE_DISPLAY = [
  {
    key: WALLET_TYPE.film,
    title: '電影票夾',
  },
  {
    key: WALLET_TYPE.meal,
    title: '餐飲票夾',
  },
];

export const FILM_ORDER_STATUS_TYPE = {
  started: 'started',
  coming: 'coming',
};

export const FILM_ORDER_STATUS_TYPE_DISPLAY = [
  {
    title: '未開演',
    key: FILM_ORDER_STATUS_TYPE.coming,
  },
  {
    title: '已開演',
    key: FILM_ORDER_STATUS_TYPE.started,
  },
];

export const FILM_STATUS_TYPE = {
  started: 'started',
  coming: 'coming',
};

export const FILM_STATUS_TYPE_DISPLAY = [
  {
    title: '現正上映',
    key: FILM_STATUS_TYPE.started,
  },
  {
    title: '即將上映',
    key: FILM_STATUS_TYPE.coming,
  },
];

export const TICKET_TYPE = {
  film: 'film',
  meal: 'meal',
};

export const TICKET_TYPE_DISPLAY = [
  {
    key: TICKET_TYPE.film,
    title: '電影票券',
  },
  {
    key: TICKET_TYPE.meal,
    title: '餐飲票券',
  },
];

export const LINK_TYPES = {
  book: 'book',
  custom: 'custom',
};

export const LINK_TYPES_DISPLAY = [
  {
    key: LINK_TYPES.book,
    title: '訂票頁面',
  },
  {
    key: LINK_TYPES.custom,
    title: '自定義連結',
  },
];

export const ORDER_STATUS = {
  PAYMENT_EXPIRED: 'PAYMENT_EXPIRED',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_REFUNDED: 'PAYMENT_REFUNDED',
  PAYMENT_WAITING: 'PAYMENT_WAITING',
  PAYMENT_FAIL: 'PAYMENT_FAIL',
  ORDER_CANCEL: 'ORDER_CANCEL',
  TICKET_CHECKIN: 'TICKET_CHECKIN',
  UNPAID_ORDER: 'UNPAID_ORDER',
};

export const ORDER_STATUS_DISPLAY = {
  PAYMENT_EXPIRED: {
    color: '#EE6D22',
    title: '超時未付款',
  },
  PAYMENT_WAITING: {
    color: '#EE6D22',
    title: '尚未付款',
  },
  PAYMENT_SUCCESS: {
    color: '#779269',
    title: '付款成功',
  },
  TICKET_CHECKIN: {
    color: '#779269',
    title: '已取票',
  },
  PAYMENT_REFUNDED: {
    color: '#555555',
    title: '已退票',
  },
  PAYMENT_FAIL: {
    color: '#B47676',
    title: '付款失敗',
  },
  ORDER_CANCEL: {
    color: '#B47676',
    title: '已取消',
  },
  UNPAID_ORDER: {
    color: '#edda12',
    title: '櫃檯取票',
  },
};
export const DAYS_DISPLAY = [
  {
    zh: '一',
    en: 'Monday',
  },
  {
    zh: '二',
    en: 'Tuesday',
  },
  {
    zh: '三',
    en: 'Wednesday',
  },
  {
    zh: '四',
    en: 'Thursday',
  },
  {
    zh: '五',
    en: 'Friday',
  },
  {
    zh: '六',
    en: 'Saturday',
  },
  {
    zh: '日',
    en: 'Sunday',
  },
];

export const SUSEE_SCREEN_LIST = [14];

export const HONEYQ_SCREEN_LIST = [9];

export const BOOKING_MODE = {
  paid: 0,
  unpaid: 1,
};

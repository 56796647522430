// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-admin-announcements-index-js": () => import("./../../../src/Templates/AdminAnnouncements/index.js" /* webpackChunkName: "component---src-templates-admin-announcements-index-js" */),
  "component---src-templates-admin-articles-index-js": () => import("./../../../src/Templates/AdminArticles/index.js" /* webpackChunkName: "component---src-templates-admin-articles-index-js" */),
  "component---src-templates-admin-banner-index-js": () => import("./../../../src/Templates/AdminBanner/index.js" /* webpackChunkName: "component---src-templates-admin-banner-index-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-admin-invoice-index-js": () => import("./../../../src/Templates/AdminInvoice/index.js" /* webpackChunkName: "component---src-templates-admin-invoice-index-js" */),
  "component---src-templates-admin-media-index-js": () => import("./../../../src/Templates/AdminMedia/index.js" /* webpackChunkName: "component---src-templates-admin-media-index-js" */),
  "component---src-templates-admin-reset-password-index-js": () => import("./../../../src/Templates/AdminResetPassword/index.js" /* webpackChunkName: "component---src-templates-admin-reset-password-index-js" */),
  "component---src-templates-admin-ticket-check-index-js": () => import("./../../../src/Templates/AdminTicketCheck/index.js" /* webpackChunkName: "component---src-templates-admin-ticket-check-index-js" */),
  "component---src-templates-book-index-js": () => import("./../../../src/Templates/Book/index.js" /* webpackChunkName: "component---src-templates-book-index-js" */),
  "component---src-templates-bulk-search-index-js": () => import("./../../../src/Templates/BulkSearch/index.js" /* webpackChunkName: "component---src-templates-bulk-search-index-js" */),
  "component---src-templates-business-index-js": () => import("./../../../src/Templates/Business/index.js" /* webpackChunkName: "component---src-templates-business-index-js" */),
  "component---src-templates-checkout-index-js": () => import("./../../../src/Templates/Checkout/index.js" /* webpackChunkName: "component---src-templates-checkout-index-js" */),
  "component---src-templates-common-questions-index-js": () => import("./../../../src/Templates/CommonQuestions/index.js" /* webpackChunkName: "component---src-templates-common-questions-index-js" */),
  "component---src-templates-credit-card-info-index-js": () => import("./../../../src/Templates/CreditCardInfo/index.js" /* webpackChunkName: "component---src-templates-credit-card-info-index-js" */),
  "component---src-templates-film-detail-index-js": () => import("./../../../src/Templates/FilmDetail/index.js" /* webpackChunkName: "component---src-templates-film-detail-index-js" */),
  "component---src-templates-film-list-index-js": () => import("./../../../src/Templates/FilmList/index.js" /* webpackChunkName: "component---src-templates-film-list-index-js" */),
  "component---src-templates-film-order-index-js": () => import("./../../../src/Templates/FilmOrder/index.js" /* webpackChunkName: "component---src-templates-film-order-index-js" */),
  "component---src-templates-forgot-password-index-js": () => import("./../../../src/Templates/ForgotPassword/index.js" /* webpackChunkName: "component---src-templates-forgot-password-index-js" */),
  "component---src-templates-intro-index-js": () => import("./../../../src/Templates/Intro/index.js" /* webpackChunkName: "component---src-templates-intro-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-login-index-js": () => import("./../../../src/Templates/Login/index.js" /* webpackChunkName: "component---src-templates-login-index-js" */),
  "component---src-templates-meal-detail-index-js": () => import("./../../../src/Templates/MealDetail/index.js" /* webpackChunkName: "component---src-templates-meal-detail-index-js" */),
  "component---src-templates-meal-list-index-js": () => import("./../../../src/Templates/MealList/index.js" /* webpackChunkName: "component---src-templates-meal-list-index-js" */),
  "component---src-templates-meal-order-index-js": () => import("./../../../src/Templates/MealOrder/index.js" /* webpackChunkName: "component---src-templates-meal-order-index-js" */),
  "component---src-templates-news-detail-index-js": () => import("./../../../src/Templates/NewsDetail/index.js" /* webpackChunkName: "component---src-templates-news-detail-index-js" */),
  "component---src-templates-news-list-index-js": () => import("./../../../src/Templates/NewsList/index.js" /* webpackChunkName: "component---src-templates-news-list-index-js" */),
  "component---src-templates-order-check-index-js": () => import("./../../../src/Templates/OrderCheck/index.js" /* webpackChunkName: "component---src-templates-order-check-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-index-js": () => import("./../../../src/Templates/Register/index.js" /* webpackChunkName: "component---src-templates-register-index-js" */),
  "component---src-templates-user-profile-index-js": () => import("./../../../src/Templates/UserProfile/index.js" /* webpackChunkName: "component---src-templates-user-profile-index-js" */),
  "component---src-templates-wallet-index-js": () => import("./../../../src/Templates/Wallet/index.js" /* webpackChunkName: "component---src-templates-wallet-index-js" */)
}


import {RectButton} from './RectButton';
import {Color} from '../constants';
import React from 'react';

export const ConfirmModalContent = (props) => {
  const {
    title = '',
    subtitle = '',
    onCancel = () => 0,
    onConfirm = () => 0,
    children,
    isLoading = false,
  } = props;
  return (
    <div style={{paddingTop: 20}}>
      <div
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          textAlign: 'center',
        }}>
        {title}
      </div>
      <div
        style={{
          fontSize: 12,
          textAlign: 'center',
        }}>
        {subtitle}
      </div>
      {children}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 20,
        }}>
        <RectButton
          onClick={onCancel}
          style={{
            width: '48%',
            backgroundColor: Color.black_10,
            color: Color.black_50,
          }}>
          取消
        </RectButton>
        <RectButton
          isLoading={isLoading}
          onClick={onConfirm}
          style={{width: '48%', color: Color.white}}>
          確定
        </RectButton>
      </div>
    </div>
  );
};

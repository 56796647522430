import styled from 'styled-components';
import {Color} from '../constants';
import {FlexRowCenterCenter} from '../widget';
import React, {Fragment} from 'react';
import {InnerSpinner} from './Spinner';

export const RectButton = (props) => {
  const {
    onClick,
    style = {},
    icon = null,
    children,
    isLoading = false,
    disabled = false,
  } = props;

  const disabledStyle = disabled
    ? {
        backgroundColor: Color.black_10,
        color: Color.black_50,
      }
    : {};

  return (
    <StyledRectButton
      disabled={disabled || isLoading}
      style={{
        ...style,
        ...disabledStyle,
      }}
      onClick={onClick}>
      {!isLoading && (
        <Fragment>
          {icon}
          {children}
        </Fragment>
      )}
      {isLoading && <InnerSpinner />}
    </StyledRectButton>
  );
};

const StyledRectButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  background-color: ${Color.orange};
  border-radius: 10px;
  color: ${Color.white};
  ${FlexRowCenterCenter};
  &:active {
    opacity: 0.3;
  }
  & > svg {
    margin-right: 8px;
  }
`;

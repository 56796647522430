import React, {useCallback, useEffect} from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import 'antd/dist/antd.less';
import './Layout.css';
import AdminLayout from './AdminLayout';
import SiteNavBar from './SiteNavBar';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {navigate} from 'gatsby-link';
import {aggregateConcessions, formatSessions} from '../Utils/Order';
import {ORDER_STATUS} from '../constants';
import {ConfirmModalContent} from './ConfirmModalContent';
import useModal from '../Hooks/useModal';

const Layout = ({children, location}) => {
  const [actions] = useOutlet('actions');

  useEffect(() => {
    if (location.pathname.indexOf('admin') > -1) {
      const init = async () => {
        try {
          await actions.AdminAutoLogin();
        } catch (e) {}
      };
      init().then(() => {
        console.log('app initialized');
      });
    }
  }, [actions, location.pathname]);

  useEffect(() => {
    const init = async () => {
      const success = await actions.autoLogin();

      if (success) {
      }
    };
    init().then(() => {
      console.log('app initialized');
    });
  }, [actions]);

  if (location.pathname.indexOf('admin') > -1) {
    return (
      <AdminLayout location={location}>
        <Helmet>
          <title>ATT 影城 後台管理系統</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Helmet>
        {children}
      </AdminLayout>
    );
  }

  const isApp = qs.parse(location.search).app === '1';

  let hasSiteNavBar = false;

  hasSiteNavBar = !(
    ['/'].indexOf(location.pathname) > -1 ||
    location.pathname.indexOf('/promo') === 0 ||
    isApp
  );

  return (
    <>
      <Helmet>
        <title>ATT吸引力影城 - ATTCINEMAS</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>

      <Wrapper>
        {hasSiteNavBar && <SiteNavBar />}
        {children}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  --contentMaxWith: 1200px;
  --topNavBarHeight: 64px;
  --basePadding: 15px 20px;
  overflow-x: hidden;
`;

export default Layout;

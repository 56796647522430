import React from 'react';
import styled from 'styled-components';
import {Link, navigate} from 'gatsby';
import {Button, Dropdown, Menu} from 'antd';
import logo from '../../static/images/app_attlogo2.png';
import {Breakpoints, Color, SITE_LINKS_CONFIG} from '../constants';
import {Menu as MenuIcon} from '@styled-icons/material/Menu';

function SiteNavBar(props) {
  return (
    <Wrapper>
      <div className="content">
        <img src={logo} alt="logo" onClick={() => navigate('/')} />
        <div style={{flex: 1}} />

        <div className="links">
          {SITE_LINKS_CONFIG.map((l, i) => (
            <Button key={i} type="text">
              <Link to={l.route}>{l.title}</Link>
            </Button>
          ))}
          <Button type="text" onClick={() => navigate('/profile')}>
            會員專區
          </Button>
        </div>

        <Dropdown
          className="dropdown"
          overlay={
            <Menu>
              {SITE_LINKS_CONFIG.map((l, i) => (
                <Menu.Item
                  key={i}
                  onClick={async () => {
                    await navigate(l.route);
                  }}>
                  {l.title}
                </Menu.Item>
              ))}
              <Menu.Divider />
              <Menu.Item
                onClick={async () => {
                  await navigate('/profile');
                }}>
                會員專區
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}>
          <MenuIcon size={25} color={Color.black} />
        </Dropdown>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--topNavBarHeight);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: 200ms;
  z-index: 1;

  & > .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: var(--basePadding);
    display: flex;
    align-items: center;
    & > img {
      width: 144px;
      height: 33px;
      object-fit: cover;
    }
    & > .dropdown {
      display: none;
    }
  }

  @media screen and (max-width: ${Breakpoints.md}px) {
    & > .content {
      & > .links {
        display: none;
      }
      & > .dropdown {
        display: block;
      }
    }
  }
`;

export default SiteNavBar;

const lang = 'tw'; // FIXME: lang implementation is undone, this is a temp config

function s(key) {
  return key; // StringTable[lang][key];
}

function d(value) {
  if (
    typeof value === 'object' &&
    value.hasOwnProperty('zh') &&
    value.hasOwnProperty('en')
  ) {
    return lang === 'tw' ? value.zh : value.en;
  }
  return value || '';
}

export {s, d};

import {getNewOutlet, getOutlet} from 'reconnect.js';
import Config from '../data.json';
import {req} from './Utils/ApiUtils';
import {buildCatDisplayMap} from './Utils/buildCatDisplayMap';
import * as CustomRenderer from '../custom/renderer';
import * as CustomCategories from '../custom/categories';
import * as CustomSortOptions from '../custom/sortOptions';
import {
  announcementList,
  banners,
  bookSeatsResp,
  cinemas,
  createMealOrderResp,
  filmAvailableSeats,
  filmAvailableTicketList,
  filmList,
  mealComboList,
  mealList,
  orderDetailFetchByBookingId,
  orderList,
} from './mock';
import jwtDecode from 'jwt-decode';

export const LAYOUT = {
  web: 1,
  app: 2,
};

const Actions = {};

let _ApiToken = null;

const _buildPath = (path) => {
  const url = new URL(path);
  const auth = _ApiToken ? `${!!url.search ? '&' : '?'}token=${_ApiToken}` : ``;
  return `${path}${auth}`;
};

function initApp() {
  const UserOutlet = getNewOutlet('user', null, {autoDelete: false});
  const AdminOutlet = getNewOutlet('admin', null, {autoDelete: false});
  const LoadingOutlet = getNewOutlet('loading', null, {autoDelete: false});
  const ActionOutlet = getNewOutlet('actions', null, {autoDelete: false});
  const LoginModalOutlet = getNewOutlet('login-modal', null, {
    autoDelete: false,
  });
  const CategoriesOutlet = getNewOutlet('categories', null, {
    autoDelete: false,
  });
  const CategoryDisplayOutlet = getNewOutlet('categoryDisplayMap', null, {
    autoDelete: false,
  });
  const SortOptionsOutlet = getNewOutlet('sortOptions', null, {
    autoDelete: false,
  });
  const CartOutlet = getNewOutlet('cart', null, {autoDelete: false});

  const categories = CustomCategories.getCategories();
  const categoryDisplayMap = buildCatDisplayMap(categories);
  const sortOptions = CustomSortOptions.getSortOptions();

  LoadingOutlet.update(false);
  LoginModalOutlet.update(false);
  CategoriesOutlet.update(categories);
  CategoryDisplayOutlet.update(categoryDisplayMap);
  SortOptionsOutlet.update(sortOptions);

  Actions.fetchCart = async (item) => {
    console.log('fetchCart', item);
  };

  Actions.updateCartConfig = async (cartConfig) => {
    const cartValue = CartOutlet.getValue();
    const nextCartValue = {
      ...cartValue,
      config: {
        ...cartValue.config,
        ...cartConfig,
      },
    };
    CartOutlet.update(nextCartValue);
  };

  Actions.renderCustomSection = (props) => {
    return CustomRenderer.renderCustomSection(props);
  };

  Actions.fetchRecords = async (queryConfigs = null) => {
    return [
      {id: '1', name: 'Item 1', price: 100, stock: 10},
      {id: '2', name: 'Item 2', price: 200, stock: 20},
      {id: '3', name: 'Item 3', price: 300, stock: 30},
    ];
  };

  Actions.fetchRecordById = async (id) => {
    return {
      id: `${id}`,
      name: `Item ${id}`,
      price: 250,
      stock: 25,
    };
  };

  Actions.fetchNews = async (id) => {
    return await req(
      `${Config.jstoreHost}/document/Article_Default/find-one?client_id=${Config.clientId}`,
      {
        method: 'POST',
        data: {
          query: {id},
        },
      },
    );
  };

  Actions.fetchNewsList = async () => {
    const resp = await req(
      `${Config.jstoreHost}/document/Article_Default/find?client_id=${Config.clientId}`,
      {
        method: 'POST',
        data: {
          query: {},
          paging: {
            offset: 0,
            limit: 100,
          },
          sorting: ['-created'],
        },
      },
    );
    return resp.results;
  };

  Actions.fetchArticles = async () => {
    const resp = await req(
      `${Config.jstoreHost}/document/Article_Default/find?token=${
        getOutlet('admin').getValue().token
      }`,
      {
        method: 'POST',
        data: {
          query: {},
          paging: {
            offset: 0,
            limit: 10,
          },
        },
      },
    );
    // TODO: Resource Component should support JStorage find API feature, such as paging and search
    return resp.results;
  };

  Actions.setLoading = async (loading) => {
    setTimeout(() => {
      LoadingOutlet.update(loading);
    }, 0);
  };

  // Start of att cinema

  Actions.fetchFilmList = async ({cinemaId}) => {
    if (!Config.ismock) {
      return await req(
        `${Config.vistaHost}/cinema/films?cinema_id=${cinemaId}&layout=${LAYOUT.web}`,
        {
          method: 'GET',
        },
      );
    }
    return filmList;
  };

  Actions.fetchFilmDetail = async ({cinemaId = 1001, filmId}) => {
    if (!Config.ismock) {
      const resp = await req(
        `${Config.vistaHost}/cinema/films?cinema_id=${cinemaId}&layout=${LAYOUT.web}`,
        {
          method: 'GET',
        },
      );
      return resp.find((f) => f.ID === filmId);
    }
    return filmList.find((f) => f.ID === filmId);
  };

  Actions.fetchFilmAvailableTicketList = async ({
    cinemaId = 1001,
    sessionId,
    isMemberTicket,
  }) => {
    if (!Config.ismock) {
      return await req(
        `${Config.vistaHost}/cinema/films/tickets?cinema_id=${cinemaId}&session_id=${sessionId}&member_ticket=${isMemberTicket}&layout=${LAYOUT.web}`,
        {
          method: 'GET',
        },
      );
    }
    return filmAvailableTicketList;
  };

  Actions.fetchFilmAvailableSeats = async ({cinemaId, sessionId}) => {
    if (!Config.ismock) {
      return await req(
        `${Config.vistaHost}/cinema/films/seats?cinema_id=${cinemaId}&session_id=${sessionId}&layout=${LAYOUT.web}`,
        {
          method: 'GET',
        },
      );
    }
    return filmAvailableSeats;
  };

  Actions.bookSeats = async (values) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(`${Config.vistaHost}/cinema/films/seats/book`),
        {
          method: 'POST',
          data: {...values, layout: LAYOUT.web},
        },
      );
    }
    return bookSeatsResp;
  };

  Actions.register = async (values) => {
    if (!Config.ismock) {
      return await req(`${Config.vistaHost}/member/register/free`, {
        method: 'POST',
        data: values,
      });
    }
    return null;
  };

  Actions.genOtp = async (values) => {
    if (!Config.ismock) {
      return await req(`${Config.vistaHost}/user/register/request`, {
        method: 'POST',
        data: values,
      });
    }
    return {
      validation: 'hello world',
    };
  };

  Actions.genForgotPasswordOtp = async (values) => {
    if (!Config.ismock) {
      return await req(`${Config.vistaHost}/user/forget/password/request`, {
        method: 'POST',
        data: values,
      });
    }
    return {
      validation: 'hello world',
    };
  };

  Actions.updatePassword = async (values) => {
    if (!Config.ismock) {
      return await req(
        `${Config.vistaHost}/member/password/forgot?token=${values.accessToken}`,
        {
          method: 'POST',
          data: {
            Password: values.password,
          },
        },
      );
    }
    //FIXME: mock data fixing
    return null;
  };

  Actions.validateOtp = async (values) => {
    if (!Config.ismock) {
      return await req(
        `${Config.authHost}/validation/confirm/${values.state}/${values.code}?client_id=${Config.clientId}`,
        {
          method: 'POST',
          data: values,
        },
      );
    }
    return {
      access_token: 'hello world',
    };
  };

  Actions.login = async (values) => {
    if (!Config.ismock) {
      const resp = await req(`${Config.vistaHost}/member/login`, {
        method: 'POST',
        data: values,
      });
      const {LoyaltySessionToken, UserSessionId} = resp;
      _ApiToken = LoyaltySessionToken;
      window.localStorage.setItem('token', LoyaltySessionToken);
      window.localStorage.setItem('userSessionId', UserSessionId);
      const me = await Actions.fetchMe();
      const result = {...me, userSessionId: UserSessionId};
      UserOutlet.update(result);
      return result;
    }
    //FIXME: mock data fixing
    return null;
  };

  Actions.autoLogin = async () => {
    const token = window.localStorage.getItem('token');
    const userSessionId = window.localStorage.getItem('userSessionId');
    _ApiToken = token;
    try {
      if (!token) {
        throw new Error('auto login failed');
      }
      const me = await Actions.fetchMe();
      const result = {...me, userSessionId};
      UserOutlet.update(result);
      return true;
    } catch (e) {
      return false;
    }
  };

  Actions.logout = async () => {
    try {
      _ApiToken = null;
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('userSessionId');
      UserOutlet.update(null);
      return true;
    } catch (e) {
      console.error('debug', e);
      return false;
    }
  };

  Actions.fetchMe = async () => {
    if (!Config.ismock) {
      const resp = await req(
        _buildPath(`${Config.vistaHost}/member/token/login`),
        {
          method: 'GET',
        },
      );
      const userSessionId = window.localStorage.getItem('userSessionId');
      const m = resp[0];
      const result = {
        id: m.MembershipID,
        username: m.MemberUserName,
        email: m.MemberEmail,
        first_name: m.MemberFirstName,
        last_name: m.MemberLastName,
        phone: m.MemberUserName.split('-')[1] || '',
        city: m.MemberAddress2.split('-')[0] || '',
        district: m.MemberAddress2.split('-')[1] || '',
        userSessionId: userSessionId,
      };
      UserOutlet.update(result);
      return result;
    }
    //FIXME: mock data fixing
    return null;
  };

  Actions.profileUpdate = async (values) => {
    if (!Config.ismock) {
      return await req(`${Config.vistaHost}/member/update`, {
        method: 'POST',
        data: values,
      });
    }
    return null;
  };

  Actions.fetchCinemas = async () => {
    if (!Config.ismock) {
      return await req(`${Config.vistaHost}/cinema/list`, {
        method: 'GET',
      });
    }
    return cinemas;
  };

  Actions.fetchOrderList = async () => {
    if (!Config.ismock) {
      return await req(_buildPath(`${Config.vistaHost}/order/list`), {
        method: 'GET',
      });
    }
    return orderList;
  };

  Actions.fetchOrderById = async (id) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(`${Config.vistaHost}/order/get?order_id=${id}`),
        {
          method: 'GET',
        },
      );
    }
    return orderList.find((o) => o.id === id);
  };

  Actions.cancelOrder = async (id) => {
    if (!Config.ismock) {
      return await req(_buildPath(`${Config.vistaHost}/order/cancel`), {
        method: 'POST',
        data: {
          order_id: id,
        },
      });
    }
    return null;
  };

  Actions.cancelUnpaidOrder = async (values) => {
    if (!Config.ismock) {
      return await req(_buildPath(`${Config.vistaHost}/order/unpaid/cancel`), {
        method: 'POST',
        data: values,
      });
    }
    return null;
  };

  Actions.refundOrder = async (values) => {
    if (!Config.ismock) {
      const resp = await req(_buildPath(`${Config.vistaHost}/order/refund`), {
        method: 'POST',
        data: values,
      });

      return resp?.params?.hpp_url || null;
    }
    return null;
  };

  Actions.validateGroupTicket = async (values) => {
    if (!Config.ismock) {
      return await req(`${Config.vistaHost}/cinema/voucher/validate`, {
        method: 'POST',
        data: {...values, layout: LAYOUT.web},
      });
    }
    return {
      ticketTypes: [
        {
          ticketTypeCode: '0015',
        },
      ],
      numberOfRedemptionsRemaining: 1,
    };
  };

  Actions.ticketCheck = async (values) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.vistaHost}/order/ticket/check?order_id=${values.id}`,
        ),
        {
          method: 'POST',
          data: {
            CinemaId: values.CinemaId,
            ItemPrinted: values.ItemPrinted,
            BookingNumber: values.BookingNumber,
            PrepareConcessionOnly: values.PrepareConcessionOnly,
          },
        },
      );
    }
    return null;
  };

  Actions.unpaidTicketCheck = async (id) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.vistaHost}/order/ticket/unpaid/check?order_id=${id}`,
        ),
        {
          method: 'GET',
        },
      );
    }
    return null;
  };

  Actions.fetchMealList = async ({cinemaId}) => {
    if (!Config.ismock) {
      return await req(
        `${Config.vistaHost}/concession/list?cinema_id=${cinemaId}&layout=${LAYOUT.web}`,
        {
          method: 'GET',
        },
      );
    }
    return mealList;
  };

  Actions.fetchMealComboList = async ({cinemaId}) => {
    if (!Config.ismock) {
      return await req(
        `${Config.vistaHost}/concession/list/combo?cinema_id=${cinemaId}&layout=${LAYOUT.web}`,
        {
          method: 'GET',
        },
      );
    }
    return mealComboList;
  };

  Actions.createMealOrder = async (values) => {
    if (!Config.ismock) {
      return await req(_buildPath(`${Config.vistaHost}/concession/insert`), {
        method: 'POST',
        data: {...values, layout: LAYOUT.web},
      });
    }
    return createMealOrderResp;
  };

  Actions.pickUpConcession = async (id) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.vistaHost}/order/concession/pickup?order_id=${id}`,
        ),
        {
          method: 'GET',
        },
      );
    }
    return null;
  };

  Actions.checkout = async (values) => {
    if (!Config.ismock) {
      let resp = await req(
        _buildPath(
          values.carrier_no
            ? `${Config.vistaHost}/checkout?order_id=${values.id}&buyer_email=${values.buyer_email}&carrier_no=${values.carrier_no}&layout=${LAYOUT.web}`
            : `${Config.vistaHost}/checkout?order_id=${values.id}&buyer_email=${values.buyer_email}&layout=${LAYOUT.web}`,
        ),
        {
          method: 'GET',
        },
      );
      const {
        params: {hpp_url},
      } = resp;
      return hpp_url;
    }
    // TODO: mock checkout response maybe an URL for redirect
    return 'https://www.google.com/';
  };

  Actions.completeOrder = async (values) => {
    if (!Config.ismock) {
      return await req(_buildPath(`${Config.vistaHost}/order/complete`), {
        method: 'POST',
        data: values,
      });
    }
    return null;
  };

  Actions.fetchOrderDetail = async (values) => {
    if (!Config.ismock) {
      return await req(_buildPath(`${Config.vistaHost}/order/detail`), {
        method: 'POST',
        data: values,
      });
    }
    return orderDetailFetchByBookingId;
  };

  //end of att cinema

  //start of dashboard
  Actions.AdminAutoLogin = async () => {
    if (typeof window !== undefined) {
      const token = window.localStorage.getItem('adminToken');
      if (token) {
        const resp = await req(
          `${Config.authHost}/jwt/access?refresh_token=${token}`,
        );
        const decoded = jwtDecode(resp.token);
        AdminOutlet.update({username: decoded.sub, ...resp, ...decoded});
        return true;
      }
    }
    return false;
  };

  Actions.AdminResetPassword = async (values) => {
    if (typeof window !== undefined) {
      const token = window.localStorage.getItem('adminToken');
      console.log('debug', values);
      if (token) {
        const resp = await req(
          `${Config.vistaHost}/user/admin/password/reset?token=${
            getOutlet('admin').getValue().token
          }`,
          {
            method: 'POST',
            data: values,
          },
        );
        return true;
      }
    }
    return false;
  };

  Actions.AdminLogin = async ({username, password}) => {
    const resp = await req(`${Config.vistaHost}/user/admin/login`, {
      method: 'POST',
      data: {username, password},
    });
    const decoded = jwtDecode(resp.token);
    AdminOutlet.update({username: decoded.sub, ...resp, ...decoded});
    if (typeof window !== undefined) {
      window.localStorage.setItem('adminToken', resp.refresh);
    }
  };

  Actions.AdminLogout = async () => {
    AdminOutlet.update(null);
    if (typeof window !== undefined) {
      window.localStorage.removeItem('adminToken');
    }
  };

  Actions.fetchAnnouncementList = async () => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/announcement/find?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {
            query: {},
          },
        },
      );
    }
    return announcementList;
  };

  Actions.fetchAnnouncementById = async (id) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/announcement/find-one?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {
            query: {id},
          },
        },
      );
    }
    return announcementList[0];
  };

  Actions.createAnnouncement = async (values) => {
    if (!Config.ismock) {
      const resp = await req(
        _buildPath(
          `${Config.jstoreHost}/document/announcement/create?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {data: values},
        },
      );
      console.log('debug', resp);
      return resp;
    }
    return {
      _id: {
        $oid: '60ec244ebc483247b450fb5d',
      },
      created: 1626088526172,
      id: 'fc051d35-db6d-40fe-b524-1c011655e4a2',
      startDate: '2021-07-12T11:15:08.101Z',
      endDate: '2021-07-12T11:15:08.101Z',
      priority: 0,
      isPublished: true,
      title: 'test001',
      subtitle: 'test001',
      context: 'test001test001test001test001',
      imgUrl:
        'https://attcinema-revtel2-com-stg.s3.ap-northeast-1.amazonaws.com/99550430-d5d8-11eb-b9f6-69c380d3f3d4-1626088521.jpeg',
      updated: 1626088526173,
    };
  };

  Actions.updateAnnouncement = async ({id, values}) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/announcement/update?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {query: {id}, data: values},
        },
      );
    }
    //FIXME : unknown
    return null;
  };

  Actions.deleteAnnouncement = async ({id}) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/announcement/find-one/delete?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {query: {id}},
        },
      );
    }
    //FIXME : unknown
    return null;
  };

  Actions.fetchBannerList = async () => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/banner/find?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {
            query: {},
          },
        },
      );
    }
    return banners;
  };

  Actions.fetchBannerById = async (id) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/banner/find-one?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {
            query: {id},
          },
        },
      );
    }
    return banners[0];
  };

  Actions.createBanner = async (values) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/banner/create?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {data: values},
        },
      );
    }

    //FIXME : unknown
    return null;
  };

  Actions.updateBanner = async ({id, values}) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/banner/update?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {query: {id}, data: values},
        },
      );
    }
    //FIXME : unknown
    return null;
  };

  Actions.deleteBanner = async ({id}) => {
    if (!Config.ismock) {
      return await req(
        _buildPath(
          `${Config.jstoreHost}/document/banner/find-one/delete?client_id=${Config.clientId}`,
        ),
        {
          method: 'POST',
          data: {query: {id}},
        },
      );
    }
    //FIXME : unknown
    return null;
  };

  Actions.storagePresigned = async ({file}) => {
    return await req(
      `${Config.uploadHost}/storage/presigned/url?client_id=${
        Config.clientId
      }&client_secret=${3939889}`,
      {
        method: 'POST',
        data: {acl: 'public-read', 'Content-Type': file.type, key: file.name},
      },
    );
  };

  Actions.updateTicketCheckPassword = async ({id, values}) => {
    return await req(
      _buildPath(
        `${Config.jstoreHost}/document/ticket_validate_password/update?client_id=${Config.clientId}`,
      ),
      {
        method: 'POST',
        data: {query: {id}, data: values},
      },
    );
  };

  Actions.fetchTicketCheckPassword = async (id) => {
    return await req(
      `${Config.jstoreHost}/document/ticket_validate_password/find-one?client_id=${Config.clientId}`,
      {
        method: 'POST',
        data: {
          query: {id},
        },
      },
    );
  };

  Actions.fetchInvoices = async (values) => {
    const token = window.localStorage.getItem('adminToken');
    const resp = await req(
      `${Config.authHost}/jwt/access?refresh_token=${token}`,
    );
    return await req(
      `${Config.vistaHost}/invoice/query?token=${resp.token}&field=period&value=${values.period}`,
      {
        method: 'GET',
      },
    );
  };

  Actions.bookInvoices = async (values) => {
    const token = window.localStorage.getItem('adminToken');
    const resp = await req(
      `${Config.authHost}/jwt/access?refresh_token=${token}`,
    );
    return await req(`${Config.vistaHost}/invoice/book?token=${resp.token}`, {
      method: 'POST',
      data: {
        period: values.period,
        booklet: values.booklet,
        einvtype: '07',
      },
    });
  };

  //end of dashboard

  ActionOutlet.update(Actions);

  console.log('App initialized');
}

initApp();
